






import Vue from "vue";

import { EbirdHotspot } from "../types";
import MapComponent from "../components/Map.vue";

export default Vue.extend({
  components: { MapComponent },
  data() {
    return {
      ebirdHotSpots: [] as EbirdHotspot[],
    };
  },
});
